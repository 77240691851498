<div class="i4b-device-info" data-cy="i4b-device-info" fxLayout="column">
  <div [ngClass]="{ loading: !site() }" class="i4b-device-info__card" fxFlex>
    @defer {
      <iot-platform-ui-tab-group
        (selectedTabChange)="onSelectedTabChange($event)"
        (editImageClicked)="editImageClicked()"
        [selectedIndex]="initialTabIndex()"
        [withArrowIcon]="true"
        direction="vertical"
        fxFlex
        position="left"
        [shadow]="true"
        [image]="device()?.imageUrl"
        [canUpdateImage]="canUpdate()"
        defaultImage="assets/images/device/Device_Default.png"
      >
        @if (infosTabOptions()?.basicInfosTab?.visible) {
          <iot-platform-ui-tab [disabled]="infosTabOptions()?.basicInfosTab?.disabled">
            <iot-platform-ui-tab-header [cssClassName]="''" [textLabel]="'DEVICES.CARD.TABS.BASIC_INFORMATION' | translate" />
            <iot-platform-ui-tab-content>
              <ng-container [ngTemplateOutlet]="deviceCard" />
            </iot-platform-ui-tab-content>
          </iot-platform-ui-tab>
        }

        @if (infosTabOptions()?.advancedSettingsTab?.visible) {
          <iot-platform-ui-tab [disabled]="infosTabOptions()?.advancedSettingsTab?.disabled">
            <iot-platform-ui-tab-header [cssClassName]="" [textLabel]="'DEVICES.CARD.TABS.ADVANCED_SETTINGS' | translate" />
            <iot-platform-ui-tab-content>
              <ng-container [ngTemplateOutlet]="deviceCard" />
            </iot-platform-ui-tab-content>
          </iot-platform-ui-tab>
        }
      </iot-platform-ui-tab-group>
    }
  </div>

  <div class="device-info-status-area" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
    <iot-platform-ui-device-latest-command-status [device]="device()" />
    <iot-platform-ui-device-info-status [device]="device()" />
  </div>
</div>

<ng-template #deviceCard>
  <iot-platform-ui-overview-card
    (cardButtonClicked)="onCardActions($event)"
    [loading]="!site()"
    [overviewCard]="overviewCard()"
    class="i4b-device-info__overview-card"
    fxFlex
  >
    <ng-container cardNameMeta>
      @if (device(); as device) {
        @if (device?.status) {
          <iot-platform-ui-event-severity-display
            data-cy="i4b-device-info-device-status"
            class="i4b-device-info__overview-card__status"
            [severity]="device.status?.name"
            [tooltip]="statusTooltip()"
          />
        }
      }
    </ng-container>
  </iot-platform-ui-overview-card>
</ng-template>
