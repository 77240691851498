import { NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CardLoaderModule, DefaultCardComponent } from '@iot-platform/iot-platform-ui';
import { AssociationByAsset } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';
import { AssetCardComponent } from '../../../../components/asset-card/asset-card.component';
import { DeviceCardComponent } from '../../../../components/device-card/device-card.component';

@Component({
  selector: 'iot4bos-ui-site-association-by-asset',
  imports: [
    FlexLayoutModule,
    TranslateModule,
    NgTemplateOutlet,
    AssetCardComponent,
    DeviceCardComponent,
    DefaultCardComponent,
    CardLoaderModule,
    UpperCasePipe
  ],
  templateUrl: './association-by-asset.component.html',
  styleUrl: './association-by-asset.component.scss'
})
export class AssociationByAssetComponent {
  association = input<AssociationByAsset>();
  laoding = input<boolean>(true);
  navigateToAssetGroup = output<string>();
  navigateToAsset = output<string>();
  navigateToDevice = output<string>();
}
