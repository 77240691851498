import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LogsPopupComponent } from '@iot-platform/audit-trail';
import { ManageTagsDialogComponent, PopupComponent } from '@iot-platform/iot-platform-ui';
import { TagCategory } from '@iot-platform/models/common';
import { Device, DeviceStatusName, Site } from '@iot-platform/models/i4b';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { EndMaintenanceDialogComponent } from '../../../components/maintenance-dialogs/end-maintenance-dialog/end-maintenance-dialog.component';
import { StartMaintenanceDialogComponent } from '../../../components/maintenance-dialogs/start-maintenance-dialog/start-maintenance-dialog.component';
import { DeviceImageDialogComponent } from '../components/device-image-dialog/device-image-dialog.component';
import { DeviceInfoFormComponent } from '../components/device-info-form/device-info-form.component';
import { DeviceInfoFormControlNames } from '../components/device-info-form/device-info-form.util';
import { DeviceMoveToFormComponent } from '../components/device-move-to-form/device-move-to-form.component';
import { DevicesFacade } from '../state/facades/devices.facade';

@Injectable({ providedIn: 'root' })
export class DevicesCtaService {
  private readonly devicesFacade: DevicesFacade = inject(DevicesFacade);
  private readonly translateService: TranslateService = inject(TranslateService);
  private readonly dialog: MatDialog = inject(MatDialog);

  constructor() {}

  editDeviceNameAndStatus(device: Device): void {
    this.editDeviceInfo(device, [DeviceInfoFormControlNames.NAME, DeviceInfoFormControlNames.STATUS]);
  }

  editDeviceGeneralInfo(device: Device): void {
    this.editDeviceInfo(device, [DeviceInfoFormControlNames.ENDPOINT]);
  }

  editDeviceConnectorInfo(device: Device) {
    this.editDeviceInfo(device, [DeviceInfoFormControlNames.OUTGOING_CONNECTOR]);
  }

  editDeviceConnectionInfo(device: Device) {
    this.editDeviceInfo(device, [DeviceInfoFormControlNames.LOGIN, DeviceInfoFormControlNames.PASSWORD]);
  }

  editDeviceInfo(device: Device, selectedFields: DeviceInfoFormControlNames[]): void {
    this.dialog
      .open(DeviceInfoFormComponent, {
        width: '600px',
        data: { device, selectedFields },
        disableClose: true
      })
      .afterClosed()
      .subscribe((toUpdate: Device) => {
        if (toUpdate) {
          this.devicesFacade.updateDevice({ ...device, ...toUpdate });
        }
      });
  }

  openAuditTrail(device: Device): void {
    this.dialog.open(LogsPopupComponent, {
      data: { concept: 'device', elementId: device.id, elementName: device.name },
      disableClose: true,
      width: '900px'
    });
  }

  manageDeviceTags(device: Device, deviceTags: TagCategory[]): void {
    this.dialog
      .open(ManageTagsDialogComponent, {
        width: '1230px',
        disableClose: true,
        data: {
          concepts: ['device'],
          selectedTags: deviceTags,
          objectName: device.name,
          currentEntityId: device.entity?.id,
          multiSelection: false,
          editable: false,
          withChildren: false,
          joinable: true,
          withParents: true,
          enforceMandatoryCategories: true
        }
      })
      .afterClosed()
      .subscribe((tags: TagCategory[]) => {
        if (tags) {
          this.devicesFacade.updateDeviceTags(device.id as string, tags);
        }
      });
  }

  editDeviceImage(device: Device): void {
    this.dialog
      .open(DeviceImageDialogComponent, {
        width: '1000px',
        data: { device },
        disableClose: true
      })
      .afterClosed()
      .pipe(filter((confirmation) => confirmation))
      .subscribe((imageUrl: string) => {
        this.devicesFacade.updateDevice({ ...device, imageUrl });
      });
  }

  activateDevice(device: Device): void {
    this.dialog
      .open(PopupComponent, {
        width: '500px',
        data: { type: 'confirm', value: this.translateService.instant('DEVICES.CARD.ACTIVATE_MESSAGE') },
        disableClose: true
      })
      .afterClosed()
      .pipe(filter((confirmation) => confirmation))
      .subscribe((_) => {
        this.devicesFacade.activateDevice(device);
      });
  }

  moveDevice(device: Device, site: Site): void {
    this.dialog
      .open(DeviceMoveToFormComponent, {
        width: '800px',
        data: { devices: [device] },
        disableClose: true
      })
      .afterClosed()
      .subscribe((updatedDevices: Device[]) => {
        if (updatedDevices) {
          this.devicesFacade.moveDevices(updatedDevices, site);
        }
      });
  }

  resetDevice(device: Device): void {
    this.dialog
      .open(PopupComponent, {
        width: '500px',
        data: { type: 'reset', value: device.name },
        disableClose: true
      })
      .afterClosed()
      .subscribe((validation) => {
        if (validation) {
          this.devicesFacade.resetDevice(device);
        }
      });
  }

  decommissionDevice(device: Device): void {
    this.dialog
      .open(PopupComponent, {
        width: '500px',
        data: { type: 'confirm', value: 'DEVICES.CARD.DECOMMISSION_CONFIRMATION_MESSAGE' },
        disableClose: true
      })
      .afterClosed()
      .subscribe((validation) => {
        if (validation) {
          this.devicesFacade.updateDevice({
            ...device,
            status: { ...device.status, name: DeviceStatusName.decommissioned }
          });
        }
      });
  }

  deleteDevice(device: Device): void {
    this.dialog
      .open(PopupComponent, {
        width: '500px',
        data: { type: 'delete', value: device.name },
        disableClose: true
      })
      .afterClosed()
      .subscribe((validation) => {
        if (validation) {
          this.devicesFacade.deleteDevice(device);
        }
      });
  }

  startOrEditMaintenance(device: Device): void {
    this.dialog
      .open(StartMaintenanceDialogComponent, {
        width: '600px',
        disableClose: true,
        data: { elementGoingIntoMaintenance: device, type: 'DEVICE' }
      })
      .afterClosed()
      .pipe(filter((deviceToUpdate: Device) => !!deviceToUpdate))
      .subscribe((deviceToUpdate: Device) => {
        this.devicesFacade.updateDevice({
          ...deviceToUpdate,
          status: { ...deviceToUpdate.status, name: DeviceStatusName.maintenance }
        } as Device);
      });
  }

  endMaintenance(device: Device): void {
    this.dialog
      .open(EndMaintenanceDialogComponent, {
        width: '600px',
        disableClose: true,
        data: { elementEndingMaintenance: device, type: 'DEVICE' }
      })
      .afterClosed()
      .pipe(filter((deviceToUpdate: Device) => !!deviceToUpdate))
      .subscribe((deviceToUpdate: Device) => {
        this.devicesFacade.updateDevice(deviceToUpdate);
      });
  }
}
