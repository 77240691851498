<form [formGroup]="siteForm" class="full-width fs-12 site-info-form" fxLayout="column">
  @if (!site()) {
    <section class="site-info-form--creation-section" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
        <mat-label class="capitalize-first-letter" id="is-shared-radio-group-label">{{ 'SITES.INFO_FORM.CREATION_TYPE' | translate }} </mat-label>
        <mat-radio-group [value]="creationViaERP" color="accent" formControlName="creationViaERP" fxLayout="row" fxLayoutGap="30px">
          <mat-radio-button [value]="false">{{ 'SITES.INFO_FORM.STANDARD_CREATION' | translate }}</mat-radio-button>
          <mat-radio-button [value]="true">{{ 'SITES.INFO_FORM.ERP_CREATION' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
      @if (creationViaERP.value) {
        <div class="site-info-form--creation-erp" fxLayout="column" fxLayoutGap="10px">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
            <mat-form-field color="accent" fxFlex>
              <mat-label>{{ 'SITES.INFO_FORM.SOURCE' | translate }}</mat-label>
              <mat-select formControlName="source" required>
                @for (source of sourceList; track source) {
                  <mat-option [value]="source">{{ source }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
            <mat-form-field color="accent" fxFlex>
              <mat-label>{{ 'SITES.INFO_FORM.SHIPTO' | translate }}</mat-label>
              <input #shiptoInput formControlName="shipto" matInput maxlength="10" required />
              <mat-hint align="end">{{ shiptoInput.value?.length || 0 }}/10</mat-hint>
            </mat-form-field>
            <iot-platform-ui-progress-bar-button
              (clickEvent)="importSite()"
              [disabled]="!source.value || !shipto.value || importLoading()"
              [enableGauge]="importLoading()"
              [gaugeColor]="'accent'"
              [title]="'SITES.INFO_FORM.IMPORT' | translate"
            ></iot-platform-ui-progress-bar-button>
          </div>
          @if (!!shipto.errors?.incorrectReference) {
            <mat-error>{{ 'SITES.INFO_FORM.ERROR_MESSAGE.INCORRECT_SHIPTO' | translate }}</mat-error>
          }
          @if (!!shipto.errors?.serverError) {
            <mat-error>{{ 'SITES.INFO_FORM.ERROR_MESSAGE.SERVER_ERROR' | translate }}</mat-error>
          }
        </div>
      }
    </section>
  }
  <section fxLayout fxLayoutGap="20px">
    <mat-form-field color="accent" fxFlex>
      <mat-label>{{ ('SITES.INFO_FORM.NAME' | translate) + ' 1' }}</mat-label>
      <input #nameInput formControlName="name" matInput maxlength="60" pattern="\S.*" required />
      @if (name.invalid && (name.errors.maxlength || name.errors.pattern)) {
        <mat-error>{{ 'SITES.INFO_FORM.ERROR_MESSAGE.NAME' | translate }}</mat-error>
      }
      @if (name.invalid && name.errors.nameExists) {
        <mat-error>{{ 'SITES.INFO_FORM.ERROR_MESSAGE.DUPLICATE' | translate }}</mat-error>
      }
      <mat-hint align="end">{{ nameInput.value?.length || 0 }}/60</mat-hint>
    </mat-form-field>
    <mat-form-field color="accent" fxFlex>
      <mat-label>{{ 'SITES.INFO_FORM.NAME2' | translate }}</mat-label>
      <input #name2Input formControlName="name2" matInput maxlength="60" pattern="\S.*" />
      @if (name2.invalid && (name2.errors.maxlength || name2.errors.pattern)) {
        <mat-error>{{ 'SITES.INFO_FORM.ERROR_MESSAGE.NAME' | translate }}</mat-error>
      }
      <mat-hint align="end">{{ name2Input.value?.length || 0 }}/60</mat-hint>
    </mat-form-field>
  </section>
  <section fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>{{ 'SITES.INFO_FORM.PARENT_ENTITY' | translate }}</mat-label>
      <input [disabled]="true" [value]="parentEntity()?.name" matInput />
    </mat-form-field>
    <div fxFlex>
      <iot-platform-ui-async-autocomplete
        (focusout)="entity.markAsTouched()"
        (reset)="resetEntity()"
        (search)="resetEntity()"
        (selectionChanged)="onEntitySelection($event)"
        [data]="sortedEntities()"
        [displayKey]="'label'"
        [displaySearchIcon]="false"
        [filterKey]="'label'"
        [initialItem]="initialEntity()"
        [minLength]="1"
        [placeholder]="'IOT_DICTIONARY.ENTITY'"
        [required]="true"
        [showSpinner]="entitiesLoading()"
      />
      @if (entity.touched && entity.invalid && entity.errors?.required) {
        <mat-error class="entity-error-wrapper">{{ 'SITES.INFO_FORM.ERROR_MESSAGE.ENTITY' | translate }}</mat-error>
      }
      @if (entity.invalid && entity.errors.airgasError) {
        <mat-error class="entity-error-wrapper">{{ 'SITES.INFO_FORM.ERROR_MESSAGE.AIRGAS_ERROR' | translate }} </mat-error>
      }
    </div>
  </section>
  <section fxLayout="row" fxLayoutGap="20px">
    <mat-form-field color="accent" fxFlex>
      <mat-label>{{ 'SITES.INFO_FORM.TYPE' | translate }}</mat-label>
      <mat-select formControlName="type" required>
        @for (siteType of allowedSiteTypes; track siteType) {
          <mat-option [value]="siteType">{{ 'SITES.CARD.TYPES.' + siteType | translate }}</mat-option>
        }
      </mat-select>
      @if (type.invalid) {
        <mat-error>{{ 'SITES.INFO_FORM.ERROR_MESSAGE.TYPE' | translate }}</mat-error>
      }
    </mat-form-field>
    <mat-form-field color="accent" fxFlex>
      <mat-label>{{ 'SITES.INFO_FORM.BUSINESS_ID' | translate }}</mat-label>
      <input #businessIdInput formControlName="businessId" matInput maxlength="30" />
      <mat-hint align="end">{{ businessIdInput.value?.length || 0 }}/30</mat-hint>
    </mat-form-field>
  </section>
  <section>
    <div fxLayout="row" fxLayoutGap="20px">
      <mat-form-field color="accent" fxFlex>
        <mat-label>{{ 'SITES.INFO_FORM.ADDRESS_1' | translate }}</mat-label>
        <input #address1Input formControlName="address1" matInput maxlength="50" />
        <mat-hint align="end">{{ address1Input.value?.length || 0 }}/50</mat-hint>
      </mat-form-field>
      <mat-form-field color="accent" fxFlex>
        <mat-label>{{ 'SITES.INFO_FORM.ADDRESS_2' | translate }}</mat-label>
        <input #address2Input formControlName="address2" matInput maxlength="50" />
        <mat-hint align="end">{{ address2Input.value?.length || 0 }}/50</mat-hint>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="20px">
      <iot-platform-ui-async-autocomplete
        (focusout)="country.markAsTouched()"
        (reset)="resetCountry()"
        (search)="resetCountry()"
        (selectionChanged)="onCountrySelection($event)"
        [data]="countries()"
        [displayKey]="'name'"
        [displaySearchIcon]="false"
        [filterKey]="'name'"
        [initialItem]="initialCountry()"
        [minLength]="1"
        [placeholder]="'SITES.INFO_FORM.COUNTRY'"
        [required]="true"
        [showSpinner]="!countries()?.length"
        fxFlex
      />
      <mat-form-field color="accent" fxFlex>
        <mat-label>{{ 'SITES.INFO_FORM.STATE' | translate }}</mat-label>
        <mat-select formControlName="state">
          @for (state of country?.value?.stateProvinces; track state) {
            <mat-option [value]="state.name">{{ state.name }}</mat-option>
          }
        </mat-select>
        @if (country.invalid) {
          <mat-hint>{{ 'SITES.INFO_FORM.STATE_HINT_MESSAGE' | translate }}</mat-hint>
        }
      </mat-form-field>
      <mat-form-field color="accent" fxFlex>
        <mat-label>{{ 'SITES.INFO_FORM.CITY' | translate }}</mat-label>
        <input #cityInput formControlName="city" matInput maxlength="30" required />
        <mat-hint align="end">{{ cityInput.value?.length || 0 }}/30</mat-hint>
      </mat-form-field>
      <mat-form-field color="accent" fxFlex>
        <mat-label>{{ 'SITES.INFO_FORM.ZIP_CODE' | translate }}</mat-label>
        <input #zipCodeInput formControlName="zipCode" matInput maxlength="10" />
        <mat-hint align="end">{{ zipCodeInput.value?.length || 0 }}/10</mat-hint>
      </mat-form-field>
    </div>
  </section>
  <section>
    <mat-form-field color="accent" fxFlex>
      <mat-label>{{ 'SITES.INFO_FORM.DESCRIPTION' | translate }}</mat-label>
      <input #descriptionInput formControlName="description" matInput maxlength="300" />
      <mat-hint align="end">{{ descriptionInput.value?.length || 0 }}/300</mat-hint>
    </mat-form-field>
  </section>
  <p class="dialog-form-tips">{{ 'IOT_DICTIONARY.MANDATORY_FIELDS' | translate }}</p>
</form>
