<div class="layout">
  <mat-tab-group color="accent" mat-align-tabs="center">
    <mat-tab label="tab_info">
      <ng-template matTabLabel>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2px">
          <span>{{ 'SITES.TABS.INFO' | translate | uppercase }}</span>
        </div>
      </ng-template>
      <div class="layout__wrapper p-12 sm:p-4 max-w-4xl xl:max-w-4xl">
        @if (site) {
          <iot4bos-ui-stock-card
            [site]="site"
            [deviceCountByFamily]="deviceCountByFamily$ | async"
            [deviceCountByFamilyLoading]="deviceCountByFamilyLoading$ | async"
            [totalDevices]="gridTotalElements$ | async"
            [totalDevicesLoading]="!(grid$ | async)"
          />
        }
      </div>
    </mat-tab>
    <mat-tab label="tab_asset_events">
      <ng-template matTabLabel>
        <div fxLayout="row" fxLayoutAlign="start center">
              <span>{{ 'SITES.ASSOCIATIONS.DEVICES' | translate | uppercase }}</span
              >&nbsp;
        </div>
      </ng-template>
      <ng-template matTabContent>
        <div class="layout__wrapper min-h-full" fxLayout="column">
          <grid-engine-grid-manager (dispatchMasterViewEngineEvent)="onMasterViewEngineEvent($event)"
                                    [currentFilters]="currentFilters"
                                    [gridSort]="gridSort$ | async"
                                    [grid]="grid$ | async"
                                    [userPermissions]="userPermissions"
                                    fxFlex
          />
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
