<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'ASSETS.SHELL.CREATE_ASSET_FROM_SCRATCH' | translate }}</h1>
      <button (click)="dialogRef.close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content create-asset-from-scratch-dialog--content" fxLayout="column">
    <mat-stepper #stepper [animationDuration]="'500'" [linear]="true" color="accent" fxFlex headerPosition="top" labelPosition="end">
      <!-- STEP 1 : ASSET MODEL -->
      <mat-step #step1 [stepControl]="firstStepForm" fxFlex>
        <ng-template matStepLabel>{{ 'IOT_DICTIONARY.MODEL' | translate }}</ng-template>

        <form [formGroup]="firstStepForm" fxLayout="column" fxLayoutAlign="space-between stretch">
          <iot4bos-ui-asset-model-form
            (fillOutAssetModel)="onAssetModelFilled($event)"
            [site]="data.site"
            [withBlankModel]="true"
          ></iot4bos-ui-asset-model-form>
        </form>
      </mat-step>

      <!-- STEP 2 : ASSET INFORMATIONS -->
      <mat-step #step2 [stepControl]="secondStepForm" fxFlex>
        <ng-template matStepLabel>{{ 'IOT_DICTIONARY.INFORMATION' | translate }}</ng-template>

        <form [formGroup]="secondStepForm" class="h-full" fxLayout="column">
          @if (stepper.selectedIndex === 1) {
            <iot4bos-ui-asset-info-form
              (fillOutAsset)="onAssetInfosFilled($event)"
              [assetModel]="model.getRawValue()"
              [optionalPropertiesInput]="model.getRawValue()?.assetOptionalProperties"
              [siteId]="site.getRawValue()?.id"
              [canUpdateAsset]="true"
            ></iot4bos-ui-asset-info-form>
          }
        </form>
      </mat-step>

      <!-- STEP 3 : ASSET TAGS -->
      <mat-step #step3 [stepControl]="thirdStepForm" fxFlex>
        <ng-template matStepLabel>{{ 'IOT_DICTIONARY.TAGS' | translate | titlecase }}</ng-template>

        <form [formGroup]="thirdStepForm" class="h-full" fxLayout="column">
          @if (stepper.selectedIndex === 2) {
            <iot-platform-ui-manage-tags-form
              (updateForm)="onAssetTagsFilled($event)"
              [data]="{
                concepts: ['asset'],
                selectedTags: [],
                objectName: asset.getRawValue()?.name,
                currentEntityId: entity.getRawValue()?.id,
                multiSelection: false,
                editable: false,
                withChildren: false,
                joinable: true,
                withParents: true,
                enforceMandatoryCategories: true
              }"
            />
          }
        </form>
      </mat-step>
    </mat-stepper>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    @if (stepper.selectedIndex === 0) {
      <button (click)="stepper.next()" [disabled]="firstStepForm.invalid" class="button-regular" color="accent" mat-raised-button>
        {{ 'ASSETS.CREATION_DIALOG.NEXT' | translate }}
      </button>
    } @else if (stepper.selectedIndex === 1) {
      <button (click)="stepper.previous()" class="button-regular" color="accent" mat-button>
        {{ 'ASSETS.CREATION_DIALOG.BACK' | translate }}
      </button>
      <button (click)="stepper.next()" [disabled]="secondStepForm.invalid" class="button-regular" color="accent" mat-raised-button>
        {{ 'ASSETS.CREATION_DIALOG.NEXT' | translate }}
      </button>
    } @else if (stepper.selectedIndex === 2) {
      <button (click)="stepper.previous()" class="button-regular" color="accent" mat-button>
        {{ 'ASSETS.CREATION_DIALOG.BACK' | translate }}
      </button>
      <button (click)="save()" [disabled]="thirdStepForm.invalid" class="button-regular" color="accent" mat-raised-button>
        {{ 'IOT_DICTIONARY.CREATE' | translate }}
      </button>
    }
  </mat-card-actions>
</mat-card>
