import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DevicesDirectoryService {
  private readonly http: HttpClient = inject(HttpClient);

  getDeviceImages(): Observable<string[]> {
    return this.http.get<string[]>('assets/data/devices-gallery.json');
  }

  getDefaultDeviceImage(): string {
    return 'assets/images/device/Device_Default.png';
  }
}
