import { computed, EnvironmentProviders, makeEnvironmentProviders, signal, WritableSignal } from '@angular/core';
import { AuthFacade } from '@iot-platform/auth';
import { LocalStorageKeys } from '@iot-platform/core';
import { FeatureMasterViewDrawerDetailsLayout, FeatureMasterViewSettings, provideFeatureMasterViewSettingsFactory } from '@iot-platform/feature/master-view';
import {
  DEFAULT_PAGE_TYPE_OPTION_DASHBOARD,
  DEFAULT_PAGE_TYPE_OPTION_GRID,
  DEFAULT_PAGE_TYPE_OPTION_MAP,
  FilterEngineMode,
  ToolbarPageType
} from '@iot-platform/iot-platform-ui';
import { Concept } from '@iot-platform/models/i4b';
import { CommentsPanelComponent } from '../../../../components/comments/comments-panel/comments-panel.component';
import { DevicesFacade } from '../../state/facades/devices.facade';
import { DevicesMasterViewDashboardService } from './devices-master-view-dashboards.service';
import { DevicesMasterViewMapService } from './devices-master-view-map.service';
import { DevicesShellMasterViewService } from './devices-shell-master-view.service';

const defaultPageType: WritableSignal<ToolbarPageType> = signal(ToolbarPageType.GRID);
const commentDrawerDetailsOpened: WritableSignal<boolean> = signal(false);

export const toggleCommentDrawerDetails = (value = false) => commentDrawerDetailsOpened.set(value);

export const devicesShellMasterViewProvider: EnvironmentProviders = makeEnvironmentProviders([
  provideFeatureMasterViewSettingsFactory({
    authFacade: AuthFacade,
    masterViewFacade: DevicesFacade,
    masterViewService: DevicesShellMasterViewService,
    masterViewDashboardService: DevicesMasterViewDashboardService,
    masterViewMapService: DevicesMasterViewMapService,
    settingsFactory: ({ masterViewService, masterViewFacade, storage }) => {
      const pageType = storage.get(LocalStorageKeys.STORAGE_DEVICES_PAGE_TYPE_KEY) as ToolbarPageType;
      defaultPageType.set(pageType ?? ToolbarPageType.GRID);

      const devicesShellSettings: Partial<FeatureMasterViewSettings> = {
        masterViewName: signal('devices'),
        grid: {
          staticDefinitionType: signal(false),
          hidePaginator: signal(false)
        },
        toolbar: {
          toolbarName: signal('DEVICES.NAME_TOOLBAR'),
          withFavoriteViews: signal(true),
          withGrids: signal(true),
          pageTypeOptions: {
            visible: signal(true),
            defaultPageType,
            options: signal([DEFAULT_PAGE_TYPE_OPTION_GRID, DEFAULT_PAGE_TYPE_OPTION_MAP, DEFAULT_PAGE_TYPE_OPTION_DASHBOARD])
          }
        },
        filterEngine: {
          withFavoriteFilters: signal(true),
          clearOnDestroy: signal(false),
          mode: signal(FilterEngineMode.FAVORITE)
        },
        dashboard: {},
        map: { enabled: signal(true) },
        drawerDetails: {
          enabled: signal(true),
          position: signal('end'),
          layout: signal(FeatureMasterViewDrawerDetailsLayout.TOOLBAR),
          mode: signal('over'),
          opened: commentDrawerDetailsOpened,
          openedOnDestroy: signal(false),
          hasBackdrop: signal(true),
          closeOnBackdropClick: signal(true),
          componentRef: computed(() => {
            const comments = (masterViewFacade as DevicesFacade).deviceComments();
            const loading = (masterViewFacade as DevicesFacade).deviceCommentsLoading();
            const withFooter = (masterViewFacade as DevicesFacade).canRead();
            const canEditComment = (masterViewFacade as DevicesFacade).canRead();
            const canDeleteComment = (masterViewFacade as DevicesFacade).canRead();
            return {
              ref: CommentsPanelComponent,
              inputs: {
                toggleDrawerDetails: (opened: boolean) => toggleCommentDrawerDetails(opened),
                dispatchCommentsEventAsInput: (event: any) => (masterViewService as DevicesShellMasterViewService).onCommentsEvent(event),
                comments,
                loading,
                withFooter,
                canEditComment,
                canDeleteComment,
                defaultConcept: Concept.DEVICE,
                filters: (masterViewService as DevicesShellMasterViewService).commentsContexts,
                withFilters: true
              }
            };
          }),
          customOpeningEventType: signal('openComments')
        }
      };

      return devicesShellSettings;
    }
  })
]);
