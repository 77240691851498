import { NgTemplateOutlet } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CardLoaderModule, DefaultCardComponent } from '@iot-platform/iot-platform-ui';
import { AssociationByDevice } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';
import { AssetCardComponent } from '../../../../components/asset-card/asset-card.component';
import { DeviceCardComponent } from '../../../../components/device-card/device-card.component';

@Component({
  selector: 'iot4bos-ui-site-association-by-device',
  imports: [FlexLayoutModule, TranslateModule, NgTemplateOutlet, AssetCardComponent, DeviceCardComponent, DefaultCardComponent, CardLoaderModule],
  templateUrl: './association-by-device.component.html',
  styleUrl: './association-by-device.component.scss'
})
export class AssociationByDeviceComponent {
  association = input<AssociationByDevice>();
  laoding = input<boolean>(true);
  navigateToAsset = output<string>();
  navigateToDevice = output<string>();
}
