import { AfterViewInit, Component, computed, DestroyRef, effect, inject, Injector, signal, Signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormControlHintType, FormControlType, FormField, FormGroupComponent } from '@iot-platform/iot-platform-ui';
import { FormsHelpers } from '@iot-platform/iot-platform-utils';
import { Connector } from '@iot-platform/models/common';
import { Device, DeviceStatusName } from '@iot-platform/models/i4b';
import { DevicesService, DeviceStatusService } from '@iot-platform/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { get, isEmpty } from 'lodash';
import { combineLatest, finalize, map, Observable, of } from 'rxjs';
import { DeviceInfoFormControlNames, DeviceInfoFormGroup } from './device-info-form.util';

@Component({
  selector: 'iot4bos-ui-device-info-form',
  templateUrl: './device-info-form.component.html',
  styleUrls: ['./device-info-form.component.scss'],
  imports: [FlexLayoutModule, MatCardModule, MatToolbarModule, MatIconModule, MatButtonModule, TranslateModule, FormGroupComponent]
})
export class DeviceInfoFormComponent implements AfterViewInit {
  public data: { device: Device; selectedFields: DeviceInfoFormControlNames[] } = inject(MAT_DIALOG_DATA);
  private readonly deviceStatusService: DeviceStatusService = inject(DeviceStatusService);
  private readonly devicesService: DevicesService = inject(DevicesService);
  private readonly dialogRef: MatDialogRef<DeviceInfoFormComponent> = inject(MatDialogRef);
  protected readonly injector: Injector = inject(Injector);
  protected readonly destroyRef: DestroyRef = inject(DestroyRef);

  deviceForm: DeviceInfoFormGroup = new FormGroup({});
  initialFormState: unknown;
  currentDevice: WritableSignal<Device> = signal(null);
  fields: WritableSignal<Partial<FormField>[]> = signal([]);
  connectors: WritableSignal<Connector[]> = signal([]);
  statuses: WritableSignal<DeviceStatusName[]> = signal([]);
  loading: WritableSignal<boolean> = signal<boolean>(true);
  lookups: Signal<{
    connectors: Connector[];
    statuses: DeviceStatusName[];
  }>;
  nameMaxLength = signal(30);
  nameMinLength = signal(1);
  endpointMaxLength = signal(50);
  disableAuthenticationFields = computed(() => {
    const device = this.currentDevice();
    return get(device, 'outgoingConnector.requestConfiguration.authentication') !== 'login';
  });

  constructor() {
    this.lookups = toSignal(this.getLookups().pipe(takeUntilDestroyed(this.destroyRef)));
    this.currentDevice.set(this.data?.device);
    this.initLookupsEffect();
    this.initializeFields();
  }

  ngAfterViewInit() {
    this.initializeFormEffect();
  }

  get selectedFields(): DeviceInfoFormControlNames[] {
    return get(this.data, 'selectedFields', []);
  }

  get outgoingConnectorControl(): AbstractControl {
    return this.deviceForm.get(DeviceInfoFormControlNames.OUTGOING_CONNECTOR);
  }

  get statusControl(): AbstractControl {
    return this.deviceForm.get(DeviceInfoFormControlNames.STATUS);
  }

  get connector() {
    const value = get(this.currentDevice(), ['outgoingConnector', 'id'], null);
    return this.connectors().find((connector: Connector) => `${value}`.trim().toLowerCase() === `${connector.id}`.toString().trim().toLowerCase());
  }

  get status() {
    const value = get(this.currentDevice(), ['status', 'name'], null);
    return this.statuses().find((s: DeviceStatusName) => `${value}`.trim().toLowerCase() === `${s}`.toString().trim().toLowerCase());
  }

  get endpointControl(): AbstractControl {
    return this.deviceForm.get(DeviceInfoFormControlNames.ENDPOINT);
  }

  get loginControl(): AbstractControl {
    return this.deviceForm.get(DeviceInfoFormControlNames.LOGIN);
  }

  get passwordControl(): AbstractControl {
    return this.deviceForm.get(DeviceInfoFormControlNames.PASSWORD);
  }

  onOutgoingConnectorSelectionChange(event) {
    this.outgoingConnectorControl.setErrors(null);
    this.outgoingConnectorControl.patchValue(event.option?.value ?? null);
  }

  onOutgoingConnectorValueChange(event): void {
    if (typeof event === 'string') {
      this.outgoingConnectorControl.setErrors({ notAConnector: true });
    } else {
      this.outgoingConnectorControl.setErrors(null);
    }
  }

  get canSubmit() {
    return this.deviceForm?.valid && JSON.stringify(this.deviceForm?.getRawValue()) !== JSON.stringify(this.initialFormState);
  }

  save() {
    if (FormsHelpers.isFormValid(this.deviceForm)) {
      const updatedDevice: Partial<Device> = { ...this.deviceForm.getRawValue() } as unknown as Partial<Device>;
      if (this.selectedFields.includes(DeviceInfoFormControlNames.STATUS)) {
        updatedDevice.status = {
          name: this.statusControl.getRawValue(),
          datetime: get(this.currentDevice(), ['status', 'datetime'], '')
        };
      }
      if (this.selectedFields.includes(DeviceInfoFormControlNames.ENDPOINT)) {
        updatedDevice.communication = { ...updatedDevice.communication, endpoint: this.endpointControl.getRawValue() };
      }
      if (this.selectedFields.includes(DeviceInfoFormControlNames.OUTGOING_CONNECTOR)) {
        updatedDevice.outgoingConnector = this.outgoingConnectorControl.getRawValue() ?? null;
      }
      if (this.selectedFields.includes(DeviceInfoFormControlNames.LOGIN)) {
        updatedDevice.credential = {
          login: isEmpty(this.loginControl.getRawValue()) ? null : this.loginControl.getRawValue(),
          password: isEmpty(this.passwordControl.getRawValue()) ? null : this.passwordControl.getRawValue()
        };
      }
      this.dialogRef.close(updatedDevice);
    }
  }

  close() {
    this.dialogRef.close();
  }

  private getLookups(): Observable<{
    connectors: Connector[];
    statuses: DeviceStatusName[];
  }> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const lookupList: Observable<any>[] = [];
    if (this.selectedFields.includes(DeviceInfoFormControlNames.OUTGOING_CONNECTOR) && get(this.data, ['device', 'id'])) {
      lookupList.push(this.devicesService.getConnectorsByDeviceId(this.data.device.id));
    } else {
      lookupList.push(of([]));
    }
    if (this.selectedFields.includes(DeviceInfoFormControlNames.STATUS)) {
      lookupList.push(this.deviceStatusService.getAvailableStatusesForEdition());
    } else {
      lookupList.push(of([]));
    }

    return combineLatest(lookupList).pipe(
      map(([connectors, statuses]) => ({ connectors, statuses })),
      finalize(() => this.loading.set(false))
    );
  }

  private initLookupsEffect() {
    effect(() => {
      const lookups = this.lookups();
      if (lookups) {
        this.connectors.set(lookups.connectors);
        this.outgoingConnectorControl?.setValue(this.connector);
        this.statuses.set(lookups.statuses);
        this.statusControl?.setValue(this.status);
      }
    });
  }

  private initializeFields(): void {
    this.fields.set([
      {
        type: FormControlType.TEXT,
        name: signal(DeviceInfoFormControlNames.NAME),
        label: signal('DEVICES.INFO_FORM.DEVICE_NAME'),
        maxLength: this.nameMaxLength,
        minLength: this.nameMinLength,
        hint: signal({ type: signal(FormControlHintType.MAX_LENGTH) }),
        required: signal(true),
        fxFlex: signal('100%')
      },
      {
        type: FormControlType.DROP_DOWN_LIST,
        name: signal(DeviceInfoFormControlNames.STATUS),
        label: signal('DEVICES.INFO_FORM.STATUS'),
        items: this.statuses,
        required: signal(true),
        fxFlex: signal('100%'),
        trackBy: (item) => item,
        displayBy: (item) => 'DEVICES.CARD.STATUS_LIST.' + item
      },
      {
        type: FormControlType.TEXT,
        name: signal(DeviceInfoFormControlNames.ENDPOINT),
        label: signal('DEVICES.INFO_FORM.ENDPOINT'),
        maxLength: this.endpointMaxLength,
        hint: signal({ type: signal(FormControlHintType.MAX_LENGTH) }),
        required: signal(false),
        fxFlex: signal('100%')
      },
      {
        fxFlex: signal('100%'),
        type: FormControlType.AUTO_COMPLETE,
        name: signal(DeviceInfoFormControlNames.OUTGOING_CONNECTOR),
        label: signal('DEVICES.INFO_FORM.OUTGOING_CONNECTOR'),
        items: this.connectors,
        required: signal(false),
        showSpinner: this.loading,
        trackBy: (item: Connector) => item?.id,
        displayBy: (item: Connector) => item?.name,
        valueChange: (event) => this.onOutgoingConnectorValueChange(event),
        selectionChange: (event) => this.onOutgoingConnectorSelectionChange(event)
      },
      {
        type: FormControlType.TEXT,
        name: signal(DeviceInfoFormControlNames.LOGIN),
        label: signal('DEVICES.INFO_FORM.LOGIN'),
        disabled: this.disableAuthenticationFields,
        required: signal(false),
        fxFlex: signal('100%')
      },
      {
        type: FormControlType.PASSWORD,
        name: signal(DeviceInfoFormControlNames.PASSWORD),
        label: signal('DEVICES.INFO_FORM.PASSWORD'),
        fxFlex: signal('100%'),
        required: signal(false),
        disabled: this.disableAuthenticationFields
      }
    ]);
  }

  private initializeFormEffect(): void {
    effect(
      () => {
        if (this.selectedFields.includes(DeviceInfoFormControlNames.NAME)) {
          this.deviceForm.addControl(
            DeviceInfoFormControlNames.NAME,
            new FormControl<string>(get(this.currentDevice(), 'name'), {
              validators: [Validators.maxLength(this.nameMaxLength()), Validators.required, Validators.pattern('\\S.*')]
            })
          );
        }
        if (this.selectedFields.includes(DeviceInfoFormControlNames.STATUS)) {
          this.deviceForm.addControl(DeviceInfoFormControlNames.STATUS, new FormControl<DeviceStatusName>(this.status, { validators: [Validators.required] }));
        }
        if (this.selectedFields.includes(DeviceInfoFormControlNames.ENDPOINT)) {
          this.deviceForm.addControl(
            DeviceInfoFormControlNames.ENDPOINT,
            new FormControl<string>(get(this.currentDevice(), ['communication', 'endpoint']), { validators: [Validators.maxLength(this.endpointMaxLength())] })
          );
        }
        if (this.selectedFields.includes(DeviceInfoFormControlNames.OUTGOING_CONNECTOR)) {
          this.deviceForm.addControl(DeviceInfoFormControlNames.OUTGOING_CONNECTOR, new FormControl<Connector>(this.connector));
        }
        if (this.selectedFields.includes(DeviceInfoFormControlNames.LOGIN)) {
          this.deviceForm.addControl(DeviceInfoFormControlNames.LOGIN, new FormControl<string>(get(this.currentDevice(), 'credential.login', null)));
        }
        if (this.selectedFields.includes(DeviceInfoFormControlNames.PASSWORD)) {
          this.deviceForm.addControl(DeviceInfoFormControlNames.PASSWORD, new FormControl<string>(get(this.currentDevice(), 'credential.password', null)));
        }
        this.initialFormState = this.deviceForm.getRawValue();
      },
      { injector: this.injector }
    );
  }
}
