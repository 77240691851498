import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DataGuardComponent } from '@iot-platform/feature/data-guard';
import { DeviceCallLogsTabComponent } from '@iot-platform/feature/device-call-logs';
import { GridEngineModule } from '@iot-platform/grid-engine';
import { DeviceInfoStatusModule, DeviceInfoToolbarComponent, DeviceLatestCommandStatusComponent, IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { DateFormatPipe, InfoDisplayPipe, LinkifyPipe } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { DeviceStatusService } from '@iot-platform/shared/services';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { EventsByConceptGridModule } from '../../../../components/events-by-concept-grid/events-by-concept-grid.module';
import { DeviceEventsTabComponent } from '../../components/device-events-tab/device-events-tab.component';
import { DeviceInfoFormComponent } from '../../components/device-info-form/device-info-form.component';
import { DeviceInfoComponent } from '../../components/device-info/device-info.component';
import { DeviceVariablesTableComponent } from '../../components/device-variables-table/device-variables-table.component';
import { DeviceOverviewComponent } from './device-overview.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    IotPlatformUiModule,
    TranslateModule,
    HighchartsChartModule,
    DeviceInfoToolbarComponent,
    GridEngineModule,
    DeviceInfoStatusModule,
    DeviceLatestCommandStatusComponent,
    EventsByConceptGridModule,
    InfoDisplayPipe,
    DateFormatPipe,
    LinkifyPipe,
    DataGuardComponent,
    DeviceInfoComponent,
    DeviceCallLogsTabComponent,
    DeviceInfoFormComponent
  ],
  declarations: [DeviceOverviewComponent, DeviceVariablesTableComponent, DeviceEventsTabComponent],
  exports: [DeviceOverviewComponent, DeviceVariablesTableComponent, DeviceLatestCommandStatusComponent],
  providers: [DeviceStatusService, DateFormatPipe, TranslateService]
})
export class DeviceOverviewModule {}
