<div class="association-by-asset" fxLayout="column" fxLayoutGap="16px">
  @if (laoding()) {
    <iot-platform-ui-card-loader [backgroundClass]="'default-card h-82'" fxFlex />
  } @else {
    @if (association()?.assetGroups?.length) {
      @for (assetGroup of association()?.assetGroups; track assetGroup.id; let i = $index) {
        <div class="association-by-asset__asset-group" fxLayout="column" fxLayoutGap="16px">
          <span class="association-by-asset__name" (click)="navigateToAssetGroup.emit(assetGroup.id)"
            >{{ i + 1 }}. {{ assetGroup.name | uppercase }} ({{ assetGroup.assets.length }})</span
          >
          @for (asset of assetGroup?.assets; track asset.id) {
            <ng-container [ngTemplateOutlet]="assetTemplate" [ngTemplateOutletContext]="{ asset: asset }" />
          } @empty {
            <ng-container [ngTemplateOutlet]="noAssociationsTemplate" />
          }
        </div>
      }
    }

    @if (association()?.assets?.length) {
      @for (asset of association()?.assets; track asset.id) {
        <ng-container [ngTemplateOutlet]="assetTemplate" [ngTemplateOutletContext]="{ asset: asset }" />
      }
    }

    @if (association()?.devices?.length) {
      <ng-container [ngTemplateOutlet]="unassignedDevicesTemplate" [ngTemplateOutletContext]="{ devices: association()?.devices }" />
    }

    @if (!association()?.assetGroups?.length && !association()?.assets?.length && !association()?.devices?.length) {
      <ng-container [ngTemplateOutlet]="noAssociationsTemplate" />
    }
  }
</div>

<ng-template #assetTemplate let-asset="asset">
  <div
    class="association-by-asset__asset"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="space-between start"
    fxLayoutAlign.lt-md="start"
    fxLayoutGap="16px"
    fxLayoutGap.lt-md="10px"
  >
    <iot4bos-ui-asset-card (clicked)="navigateToAsset.emit($event.id)" [asset]="asset" fxFlex="50%" fxFlex.lt-md="100%" />

    <section fxFlex="50%" fxFlex.lt-md="100%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
      @for (device of asset.devices; track device.id) {
        <iot4bos-ui-device-card fxFlex class="association-by-asset__device" [device]="device" (clicked)="navigateToDevice.emit($event.id)" />
      } @empty {
        <iot-platform-ui-default-card class="association-by-asset__default-card--no-items" fxFlex [backgroundClass]="'h-82'" [title]="'SITES.ASSOCIATIONS.NO_ASSOCIATED_DEVICE' | translate" />
      }
    </section>
  </div>
</ng-template>

<ng-template #unassignedDevicesTemplate let-devices="devices">
  <div
    class="association-by-asset__asset"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="space-between start"
    fxLayoutAlign.lt-md="start"
    fxLayoutGap="16px"
    fxLayoutGap.lt-md="10px"
  >
    <iot-platform-ui-default-card [backgroundClass]="'h-82'" [title]="'SITES.ASSOCIATIONS.NO_ASSOCIATED_ASSET' | translate" fxFlex="50%" fxFlex.lt-md="100%" />

    <section fxFlex="50%" fxFlex.lt-md="100%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
      @for (device of devices; track device.id) {
        <iot4bos-ui-device-card fxFlex class="association-by-asset__device" [device]="device" (clicked)="navigateToDevice.emit($event.id)" />
      }
    </section>
  </div>
</ng-template>

<ng-template #noAssociationsTemplate>
  <div
    class="association-by-asset__asset"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="space-between start"
    fxLayoutAlign.lt-md="start"
    fxLayoutGap="16px"
    fxLayoutGap.lt-md="10px"
  >
    <iot-platform-ui-default-card [backgroundClass]="'h-82'" [title]="'SITES.ASSOCIATIONS.NO_ASSOCIATED_ASSET' | translate" fxFlex="50%" fxFlex.lt-md="100%" />
    <iot-platform-ui-default-card [backgroundClass]="'h-82'" [title]="'SITES.ASSOCIATIONS.NO_ASSOCIATED_DEVICE' | translate" fxFlex="50%" fxFlex.lt-md="100%" />
  </div>
</ng-template>
