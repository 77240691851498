<div class="association-by-device" fxLayout="column" fxLayoutGap="16px">
  @if (laoding()) {
    <iot-platform-ui-card-loader [backgroundClass]="'default-card h-82'" fxFlex />
  } @else {
    @if (association()?.devices?.length) {
      @for (device of association()?.devices; track device.id) {
        <ng-container [ngTemplateOutlet]="deviceTemplate" [ngTemplateOutletContext]="{ device: device }"></ng-container>
      }
    }

    @if (association()?.assets?.length) {
      <ng-container [ngTemplateOutlet]="unassignedAssetsTemplate"
                    [ngTemplateOutletContext]="{ assets: association()?.assets }"></ng-container>
    }

    @if (!association()?.devices?.length && !association()?.assets?.length) {
      <ng-container [ngTemplateOutlet]="noAssociationsTemplate"></ng-container>
    }
  }
</div>

<ng-template #deviceTemplate let-device="device">
  <div
    class="association-by-device__device"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="space-between start"
    fxLayoutAlign.lt-md="start"
    fxLayoutGap="16px"
    fxLayoutGap.lt-md="10px"
  >
    <iot4bos-ui-device-card (clicked)="navigateToDevice.emit($event.id)" [device]="device" fxFlex />

    <section fxFlex="50%" fxFlex.lt-md="100%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
      @for (asset of device.assets; track asset.id) {
        <iot4bos-ui-asset-card class="association-by-device__asset" [asset]="asset"
                               (clicked)="navigateToAsset.emit($event.id)" />
      } @empty {
        <iot-platform-ui-default-card class="association-by-device__default-card--no-items" fxFlex
                                      [backgroundClass]="'h-82'"
                                      [title]="'SITES.ASSOCIATIONS.NO_ASSOCIATED_ASSET' | translate" />
      }
    </section>
  </div>
</ng-template>

<ng-template #unassignedAssetsTemplate let-assets="assets">
  <div
    class="association-by-device__device"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="space-between start"
    fxLayoutAlign.lt-md="start"
    fxLayoutGap="16px"
    fxLayoutGap.lt-md="10px"
  >
    <iot-platform-ui-default-card [backgroundClass]="'h-82'"
                                  [title]="'SITES.ASSOCIATIONS.NO_ASSOCIATED_DEVICE' | translate" fxFlex="50%"
                                  fxFlex.lt-md="100%" />

    <section fxFlex="50%" fxFlex.lt-md="100%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
      @for (asset of assets; track asset.id) {
        <iot4bos-ui-asset-card fxFlex class="association-by-device__asset" [asset]="asset"
                               (clicked)="navigateToDevice.emit($event.id)" />
      }
    </section>
  </div>
</ng-template>

<ng-template #noAssociationsTemplate>
  <div
    class="association-by-device__device"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="space-between start"
    fxLayoutAlign.lt-md="start"
    fxLayoutGap="16px"
    fxLayoutGap.lt-md="10px"
  >
    <iot-platform-ui-default-card [backgroundClass]="'h-82'"
                                  [title]="'SITES.ASSOCIATIONS.NO_ASSOCIATED_DEVICE' | translate" fxFlex="50%"
                                  fxFlex.lt-md="100%" />
    <iot-platform-ui-default-card [backgroundClass]="'h-82'"
                                  [title]="'SITES.ASSOCIATIONS.NO_ASSOCIATED_ASSET' | translate" fxFlex="50%"
                                  fxFlex.lt-md="100%" />
  </div>
</ng-template>
