import { ChangeDetectionStrategy, Component, inject, signal, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormControlHintType, FormControlType, FormField, FormGroupComponent } from '@iot-platform/iot-platform-ui';
import { NameValidators } from '@iot-platform/iot-platform-utils';
import { AssetGroupsService } from '@iot-platform/iot4bos/data-access/asset-groups';
import { AssetGroup } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  imports: [TranslateModule, FlexLayoutModule, MatCardModule, MatIconModule, MatToolbarModule, MatButtonModule, FormGroupComponent],
  selector: 'iot4bos-ui-create-asset-group-dialog',
  templateUrl: './create-asset-group-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateAssetGroupDialogComponent {
  readonly data: { assetGroup: AssetGroup; siteId: string } = inject(MAT_DIALOG_DATA);
  readonly assetGroupsService: AssetGroupsService = inject(AssetGroupsService);
  readonly dialogRef: MatDialogRef<CreateAssetGroupDialogComponent> = inject(MatDialogRef<CreateAssetGroupDialogComponent>);

  form: UntypedFormGroup = new FormGroup({
    name: new FormControl<string>(this.data.assetGroup?.name ?? '', {
      validators: [Validators.maxLength(50), Validators.required],
      asyncValidators: [NameValidators.asyncUniqueNameValidator(this.assetGroupsService, this.data.assetGroup?.name ?? '', this.data.siteId)]
    }),
    erpReference: new FormControl<string>(this.data.assetGroup?.erpReference ?? ''),
    description: new FormControl<string>(this.data.assetGroup?.description ?? '', [Validators.maxLength(300)])
  });

  fields: Signal<Partial<FormField>[]> = signal([
    {
      type: FormControlType.TEXT,
      name: signal('name'),
      label: signal('FAVORITE_VIEW.FORM.PLACEHOLDER_NAME'),
      required: signal(true),
      maxLength: signal(50),
      fxFlex: signal('100%'),
      hint: signal({ type: signal(FormControlHintType.MAX_LENGTH) })
    },
    {
      type: FormControlType.TEXT,
      name: signal('erpReference'),
      label: signal('IOT_DICTIONARY.ERP_REFERENCE'),
      required: signal(false),
      fxFlex: signal('100%')
    },
    {
      type: FormControlType.TEXT,
      name: signal('description'),
      label: signal('IOT_DICTIONARY.DESCRIPTION'),
      required: signal(false),
      maxLength: signal(300),
      fxFlex: signal('100%'),
      hint: signal({ type: signal(FormControlHintType.MAX_LENGTH) })
    }
  ]);

  initialFormState: unknown = this.form.getRawValue();

  get canSubmit() {
    return this.form?.valid && JSON.stringify(this.form?.getRawValue()) !== JSON.stringify(this.initialFormState);
  }

  save(): void {
    this.dialogRef.close(this.form.getRawValue());
  }

  close(): void {
    this.dialogRef.close();
  }
}
