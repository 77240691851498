<div
  (click)="overviewCard()?.onClick?.($event)"
  [ngClass]="{ 'overview-card--clickable': !!overviewCard()?.onClick }"
  class="overview-card iot-platform-ui-overview-card {{ overviewCard()?.cssClass }}"
  data-cy="iot-platform-ui-overview-card"
  fxFlex.lt-md="100%"
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutGap="16px"
>
  @if (loading()) {
    <iot-platform-ui-card-loader data-cy="iot-platform-ui-overview-card-loader" fxFlex [backgroundClass]="'mv-detail-card h-140'" />
  } @else {
    @if (overviewCard()?.image || overviewCard()?.defaultImage) {
      <div
        class="overview-card-col overview-card-img-container"
        fxLayoutAlign.lt-md="center center"
        [fxFlex]="overviewCard()?.imageWidth ?? '30%'"
        fxFlex.lt-md="100%"
      >
        <img
          data-cy="iot-platform-ui-overview-card-image"
          [src]="overviewCard()?.image ?? overviewCard()?.defaultImage"
          class="overview-card-img"
          #imageRef
          (error)="imageRef.src = overviewCard()?.defaultImage ?? ''"
        />
        @if (overviewCard()?.canUpdateImage) {
          <button
            mat-icon-button
            class="overview-card-edit-image-button regular-round-button"
            (click)="$event.preventDefault(); $event.stopPropagation(); overviewCard()?.imageMetaAction.dispatchEvent?.()"
          >
            <mat-icon>edit</mat-icon>
          </button>
        }
      </div>
    } @else if (overviewCard()?.defaultSvgIcon) {
      <div
        class="overview-card-col overview-card-img-container overview-card-img-container--default-svg"
        fxLayoutAlign.lt-md="center center"
        [fxFlex]="overviewCard()?.imageWidth ?? '30%'"
        fxFlex.lt-md="100%"
      >
        <mat-icon class="overview-card-icon" data-cy="iot-platform-ui-overview-card-image" [svgIcon]="overviewCard()?.defaultSvgIcon" />
        @if (overviewCard()?.canUpdateImage) {
          <button
            mat-icon-button
            class="overview-card-edit-image-button regular-round-button"
            (click)="$event.preventDefault(); $event.stopPropagation(); overviewCard()?.imageMetaAction.dispatchEvent?.()"
          >
            <mat-icon>edit</mat-icon>
          </button>
        }
      </div>
    }
    <div class="overview-card-col" fxFlex>
      @if (overviewCard()?.name || overviewCard()?.nameMetaAction || canDisplayCTA()) {
        <div class="overview-card-header" fxLayout="row" fxLayoutAlign="start stretch">
          <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
            @if (overviewCard()?.name) {
              <div data-cy="iot-platform-ui-overview-card-title" class="overview-card-name" fxLayout="row" fxLayoutGap="10px">
                <span>{{ overviewCard()?.name | infoDisplay }}</span>

                <ng-content select="[cardNameMeta]" />

                <ng-container [ngTemplateOutlet]="tmplValueMeta" [ngTemplateOutletContext]="{ property: overviewCard() }" />
              </div>
            }

            @if (overviewCard()?.nameMetaAction) {
              <ng-container [ngTemplateOutlet]="tmplAction" [ngTemplateOutletContext]="{ action: overviewCard()?.nameMetaAction }" />
            }
          </div>

          @if (canDisplayCTA()) {
            <div class="overview-card-buttons app-round-button" data-cy="iot-platform-ui-overview-card-actions">
              <button #overviewCardMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="overviewCardMenu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #overviewCardMenu="matMenu">
                @for (action of overviewCard()?.actions; track $index) {
                  @if ((action?.visible !== undefined && action.visible) || action?.visible === undefined) {
                    @if (action?.divider) {
                      <mat-divider></mat-divider>
                    }
                    <div fxLayoutAlign="start center" fxLayoutGap="6px">
                      <button
                        data-cy="iot-platform-ui-overview-card-action"
                        class="iot-platform-ui-overview-card-action-{{ action.type }}"
                        (click)="cardButtonClicked.emit(action.type)"
                        [disabled]="action.disabled"
                        mat-menu-item
                      >
                        {{ action.label | translate | uppercase }}
                      </button>
                      <ng-container [ngTemplateOutletContext]="{ property: action }" [ngTemplateOutlet]="tmplValueMeta" />
                    </div>
                  }
                }
              </mat-menu>
            </div>
          }
        </div>
      }
      <div class="overview-card-content">
        <mat-accordion multi>
          @for (section of overviewCard()?.sections; track sectionIndex; let sectionIndex = $index) {
            @if (!!section?.visibleExpression) {
              @if (section.visibleExpression(section?.data)) {
                <ng-container [ngTemplateOutlet]="tmplSection" [ngTemplateOutletContext]="{ section: section, index: sectionIndex }" />
              }
            } @else {
              <ng-container [ngTemplateOutlet]="tmplSection" [ngTemplateOutletContext]="{ section: section, index: sectionIndex }" />
            }
          }
        </mat-accordion>
      </div>
    </div>
  }
</div>

<ng-template #tmplSection let-index="index" let-section="section">
  @if (section?.collapsible) {
    <mat-expansion-panel
      data-cy="iot-platform-ui-overview-card-section-expansion-panel"
      class="iot-platform-ui-overview-card-section__expansion-panel {{ section?.cssClass }}"
      [expanded]="getSectionExpandedByIndex(section, index)"
      (afterExpand)="onSectionExpandChange(section, index, true)"
      (afterCollapse)="onSectionExpandChange(section, index, false)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <ng-container [ngTemplateOutlet]="tmplTitle" [ngTemplateOutletContext]="{ section: section }" />
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div
        data-cy="iot-platform-ui-overview-card-section"
        class="overview-card__section {{ section?.cssClass }}"
        [ngStyle]="section?.style"
        fxLayout="row wrap"
      >
        <ng-container [ngTemplateOutlet]="tmplProperties" [ngTemplateOutletContext]="{ section: section }" />
      </div>
    </mat-expansion-panel>
  } @else {
    <div data-cy="iot-platform-ui-overview-card-section" class="overview-card__section {{ section?.cssClass }}" [ngStyle]="section?.style" fxLayout="row wrap">
      <ng-container [ngTemplateOutlet]="tmplTitle" [ngTemplateOutletContext]="{ section: section }" />

      <ng-container [ngTemplateOutlet]="tmplProperties" [ngTemplateOutletContext]="{ section: section }" />
    </div>
  }
</ng-template>

<ng-template #tmplTitle let-section="section">
  <div fxFlex="100%" fxLayout="row" fxLayoutAlign="start center">
    @if (section?.title) {
      <iot-platform-ui-detail-popup-section-header
        data-cy="iot-platform-ui-overview-card-section-header"
        fxFlex="95%"
        class="overview-card-section-title"
        [headerTitle]="section.title | translate | uppercase"
      />
    } @else if (section?.titleComponentRef) {
      <ng-container [ngTemplateOutlet]="tmplTitleComponentRef" [ngTemplateOutletContext]="{ titleComponentRef: section.titleComponentRef }" />
      <iot-platform-ui-detail-popup-section-header data-cy="iot-platform-ui-overview-card-section-header" class="overview-card-section-title" fxFlex />
    }

    @if (section?.action) {
      <div fxFlex>
        <ng-container [ngTemplateOutlet]="tmplAction" [ngTemplateOutletContext]="{ action: section.action }" />
      </div>
    }
  </div>
</ng-template>

<ng-template #tmplTitleComponentRef let-titleComponentRef="titleComponentRef">
  <ng-container *ngComponentOutlet="titleComponentRef.ref; inputs: titleComponentRef.inputs" />
</ng-template>

<ng-template #tmplProperties let-section="section">
  @defer (on viewport; on timer(500ms)) {
    @for (property of section.properties; track $index) {
      @if (property?.componentRef) {
        @if (!!property?.visibleExpression) {
          @if (property.visibleExpression(property?.data)) {
            <ng-container [ngTemplateOutlet]="tmplComponentRef" [ngTemplateOutletContext]="{ property: property }" />
          }
        } @else {
          <ng-container [ngTemplateOutlet]="tmplComponentRef" [ngTemplateOutletContext]="{ property: property }" />
        }
      } @else if (!!property?.visibleExpression) {
        @if (property?.visibleExpression(property?.data)) {
          <ng-container [ngTemplateOutlet]="tmplDisplayProperty" [ngTemplateOutletContext]="{ property: property }" />
        }
      } @else {
        <ng-container [ngTemplateOutlet]="tmplDisplayProperty" [ngTemplateOutletContext]="{ property: property }" />
      }
    }
  } @placeholder {
    <div fxFlex fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
        <iot-platform-ui-skeleton borderRadius="16px" />
        <iot-platform-ui-skeleton borderRadius="16px" width="10rem" />
        @for (idx of randomSkeletonsSizes(); track $index) {
          <iot-platform-ui-skeleton borderRadius="16px" [height]="$index % 2 ? '1.5rem' : '1rem'" [width]="idx + '%'" />
        }
      </div>
    </div>
  }
</ng-template>

<ng-template #tmplComponentRef let-property="property">
  @if (property?.label) {
    <div
      [fxFlex]="property.width !== undefined && property.width !== null ? property.width : '100%'"
      class="overview-card__property {{ property?.cssClass }}"
      [ngStyle]="property?.style"
      data-cy="iot-platform-ui-overview-card-section-property"
      fxFlex.lt-md="100%"
      fxFlexLayout="row wrap"
      fxLayoutGap="5px"
    >
      @if (overviewCard()?.justifyContent) {
        <span fxFlex class="capitalize-first-letter overview-card__property-label overview-card__property-label--justified">{{
          property.label | translate
        }}</span>
      } @else {
        <span class="capitalize-first-letter overview-card__property-label">{{ property.label | translate }}</span>
      }
      <span [matTooltip]="property.value | translate" class="overview-card__property-value" matTooltipClass="regular-tooltip">
        <ng-container *ngComponentOutlet="property.componentRef.ref; inputs: property.componentRef?.inputs" />
      </span>
      <ng-container [ngTemplateOutlet]="tmplValueMeta" [ngTemplateOutletContext]="{ property: property }" />
    </div>
  } @else {
    <div [fxFlex]="property.width !== undefined && property.width !== null ? property.width : '100%'" fxFlex.lt-md="100%">
      <ng-container *ngComponentOutlet="property.componentRef.ref; inputs: property.componentRef?.inputs" />
      <ng-container [ngTemplateOutlet]="tmplValueMeta" [ngTemplateOutletContext]="{ property: property }" />
    </div>
  }
</ng-template>

<ng-template #tmplDisplayProperty let-property="property">
  <div
    [fxFlex]="property.width"
    [ngStyle]="property?.style"
    class="overview-card__property {{ property?.cssClass }}"
    data-cy="iot-platform-ui-overview-card-section-property"
    fxFlex.lt-md="100%"
    fxFlexLayout="row"
    fxLayoutGap="5px"
  >
    @if (property?.label) {
      @if (overviewCard()?.justifyContent) {
        <span fxFlex class="capitalize-first-letter overview-card__property-label overview-card__property-label--justified">{{
          property.label | translate
        }}</span>
      } @else {
        <span class="capitalize-first-letter overview-card__property-label">{{ property.label | translate }}</span>
      }
    }

    <span
      (click)="property?.onClick?.($event)"
      [matTooltip]="property.value | translate"
      [ngClass]="{ 'overview-card__property-value--clickable': !!property?.onClick }"
      class="overview-card__property-value"
      matTooltipClass="regular-tooltip"
      >{{ property.value | infoDisplay | translate }}</span
    >
    <ng-container [ngTemplateOutletContext]="{ property: property }" [ngTemplateOutlet]="tmplValueMeta" />
  </div>
</ng-template>

<ng-template #tmplValueMeta let-property="property">
  @if (property?.valueMeta?.icon) {
    @if (!!property?.valueMeta?.visibleExpression) {
      @if (property?.valueMeta?.visibleExpression?.(property?.data)) {
        <mat-icon class="overview-card__property-value-meta-icon" [matTooltip]="property?.valueMeta?.tooltip | translate" matTooltipClass="regular-tooltip"
          >{{ property?.valueMeta?.icon }}
        </mat-icon>
      }
    } @else {
      <mat-icon class="overview-card__property-value-meta-icon" [matTooltip]="property?.valueMeta?.tooltip | translate" matTooltipClass="regular-tooltip"
        >{{ property?.valueMeta?.icon }}
      </mat-icon>
    }
  }
</ng-template>

<ng-template #tmplAction let-action="action">
  @if (action?.type && ((action?.visible !== undefined && action.visible) || action?.visible === undefined)) {
    <button
      (click)="$event.preventDefault(); $event.stopPropagation(); action?.dispatchEvent?.()"
      [disabled]="action?.disabled"
      class="light-button regular-round-button iot-platform-ui-overview-card__button-action iot-platform-ui-overview-card__button-action--{{ action.type }}"
      data-cy="iot-platform-ui-overview-card-button-action"
      [matTooltip]="action?.tooltip | translate"
      mat-mini-fab
      matTooltipClass="regular-tooltip"
      matTooltipPosition="below"
    >
      @if (action?.icon) {
        <mat-icon>{{ action?.icon }}</mat-icon>
      } @else {
        {{ action?.label | translate }}
      }
    </button>
  }
</ng-template>
